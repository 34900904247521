<template>
    <div class="p-5 bg-white rounded-lg">
        <h1 class="my-5 text-xl text-center">詢問單報表</h1>
        <table class="myTable">
            <thead>
                <tr>
                    <th>暱稱</th>
                    <th>詢問單數量</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, key) in datas.result"
                    :key="key"
                    class="text-center transition duration-200"
                >
                    <td>
                        {{ item.name }}
                    </td>
                    <td>
                        {{ item.count | formatCurrency }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="my-2 mr-5 text-lg font-bold">總計:</div>
        <div class="flex items-center px-5 py-5 text-white bg-blue-800">
            <ul class="flex">
                <li class="mr-5">
                    會員數: <strong>{{ datas.userIds.length }}</strong>
                </li>
                <li>
                    詢問單數:
                    <strong
                        >{{
                            sumBy(
                                Object.values(datas.result).map((item) => item),
                                " "
                            )
                        }}
                    </strong>
                </li>
            </ul>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
// 計算 陣列中 物件指定 key 數字總合方法
import { sumBy as _sumBy } from "lodash/math";

// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const datas = ref({
    userIds: [],
    result: {}
  });
  const sumBy = _sumBy;
  async function getVorders() {
    const result = await proxy.$api.VorderReportApi();
    datas.value = result.data;
  }
  onMounted(async () => {
    await getVorders();
  });
  return {
    datas,
    sumBy
  };
};
export default __sfc_main;
</script>
